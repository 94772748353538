export enum Viewport {
  "xsmall" = 480,
  "small" = 764,
  "medium" = 1200,
  "large" = 1250,
}

export enum HubspotForms {
  DEMO_CONTACT = "demo-contact",
  DEMO_PROGRAM = "demo-program",
  TEST_REWARD = "test-reward",
  RECIPIENT_TOUR = "recipient-tour",
  NEWSLETTER_SUBSCRIPTION = "newsletter-subscription",
  LEGAL_DISBURSEMENTS = "legal-disbursements",
  EMPLOYEES_HOLIDAY_GIFTING = "employees-holiday-gifting",
  INCENTIVE_CALCULATOR = "incentive-calculator",
  CAC_CALCULATOR = "cac-calculator",
}

export enum ConversionIdentifiers {
  DEMO_CONTACT = "request_demo_contact_info",
  DEMO_PROGRAM = "request_demo_program_type",
  TEST_REWARD = "test_reward_email",
}

export const getHubspotCompanyUrl = (domain: string) =>
  `/api/hubspot-company/?domain=${domain}`
const getHubspotFormUrl = (form: HubspotForms) => `/api/hubspot/?form=${form}`
export const getValidateEmailUrl = (email: string) =>
  `/api/validate-email/?email=${encodeURIComponent(email)}`

// hubspot forms
export const DEMO_CONTACT = getHubspotFormUrl(HubspotForms.DEMO_CONTACT)
export const INCENTIVE_CALCULATOR = getHubspotFormUrl(
  HubspotForms.INCENTIVE_CALCULATOR,
)
export const CAC_CALCULATOR = getHubspotFormUrl(HubspotForms.CAC_CALCULATOR)
export const DEMO_PROGRAM = getHubspotFormUrl(HubspotForms.DEMO_PROGRAM)
export const TEST_REWARD = getHubspotFormUrl(HubspotForms.TEST_REWARD)
export const RECIPIENT_TOUR = getHubspotFormUrl(HubspotForms.RECIPIENT_TOUR)

export const NEWSLETTER_SUBSCRIPTION = getHubspotFormUrl(
  HubspotForms.NEWSLETTER_SUBSCRIPTION,
)
export const LEGAL_DISBURSEMENTS = getHubspotFormUrl(
  HubspotForms.LEGAL_DISBURSEMENTS,
)

export const ROOT_SCROLLABLE_ELEMENT = "ROOT_SCROLLABLE_ELEMENT"

export const EMPLOYEES_HOLIDAY_GIFTING = getHubspotFormUrl(
  HubspotForms.EMPLOYEES_HOLIDAY_GIFTING,
)

// Volatile marketing data
export const YOUTUBE_DEMO_VIDEO_URL =
  "https://www.youtube.com/watch?v=6RXO3DjbRhY"

export const CAMPAIGNS = [
  "20482318242",
  "11623543188",
  "21194430628",
  "21194430424",
  "21417815506",
  "21417815509",
]

export const VALID_EMAIL = "VALID_EMAIL"
export const BLACKLIST_EMAIL = "BLACKLIST_EMAIL"
